@import '@/styles/abstracts/index';

.footer {
  padding: 3rem 0;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1.5rem;

  @media (width >= $breakpoint-sm) {
    gap: 1.5rem;
  }

  @media (width >= $breakpoint-md) {
    flex-direction: row;
    justify-content: space-between;
    width: 100vw;
    padding: 2rem 1.5rem;
  }

  .section + .section {
    border: 2px solid var(--foreground);
    background-color: var(--background);
    padding: 0 1.5rem;
    height: 50px;
    border-radius: 25px;

    @media (width < $breakpoint-sm) {
      background: none;
      border: none;
    }

    p:first-child {
      @media (width >= $breakpoint-sm) {
        padding-right: 0.75rem;
        border-right: 2px solid var(--foreground);
      }
    }

    p button {
      transform: scale(0.8);
    }

    @media (width < $breakpoint-sm) {
      flex-direction: column;
    }
  }
}

.section {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;

  p {
    align-self: stretch;
    display: flex;
    align-items: center;
  }
}

.actions {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  min-height: 35px;
}

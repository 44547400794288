@import 'variables.module';

%screen-reader-only {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

%icon-button {
  cursor: pointer;
  width: $icon-button-size;
  height: $icon-button-size;
  border-radius: 50%;
  border: 2px solid var(--foreground);
  background-color: var(--background);
  @include merge-transitions((border-color));

  &::before {
    content: none;
  }
}

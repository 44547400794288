* {
  box-sizing: border-box;
  position: relative;
  min-width: 0;
}

body {
  min-height: 100dvh;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  text-wrap: balance;
}

p {
  text-wrap: pretty;
}

div:has(.grecaptcha-badge) {
  position: absolute;
}

.grecaptcha-badge {
  visibility: hidden !important;
}

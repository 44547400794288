@import '@/styles/abstracts/index';

.actions {
  position: absolute;
  background-color: var(--background);
  border: 2px solid var(--foreground);
  border-radius: 10px;
  padding: 0.75rem;
  width: 100px;
  top: 50px;
  left: -35px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  transform: scaleY(0);
  opacity: 0;
  transform-origin: top right;
  @include merge-transitions((transform, opacity));

  & li > a {
    @include merge-transitions((color));

    &::before {
      background: none;
    }

    &:hover {
      color: $accent;
    }
  }

  &.opened {
    transform: scaleY(1);
    opacity: 1;
  }
}

@import '@/styles/abstracts/index';

.icon-button {
  @extend %icon-button;

  & svg {
    position: absolute;
    left: 50%;
    top: 3px;
    transform: translateX(-50%);
    width: 20px;
    height: 20px;
    fill: var(--foreground);
    @include merge-transitions((fill));
  }

  @media (hover: hover) {
    &:hover {
      border-color: $accent;
      & svg {
        fill: $accent;
      }
    }
  }

  &.active {
    border-color: $accent;
    & svg {
      fill: $accent;
    }
  }
}

.screen-reader-only {
  @extend %screen-reader-only;
}

@import '@/styles/abstracts/index';

.nav-link {
  a {
    text-transform: uppercase;
    display: inline-block;

    &.active {
      color: $accent;
      pointer-events: none;
      @include degular($wght: 700, $opsz: 16);

      &::before {
        transform-origin: left;
        transform: scaleX(1);
      }
    }
  }
}

@import '@/styles/abstracts/index';

.hamburger {
  cursor: pointer;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  outline: none;
  border: none;

  svg {
    stroke: var(--foreground);
    stroke-width: 2;
    stroke-linecap: round;
    @include merge-transitions((stroke));

    &.active {
      stroke: $accent;
    }
  }

  @media (hover: hover) {
    &:hover {
      svg {
        stroke: $accent;
      }
    }
  }
}

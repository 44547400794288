:root {
  --background: #{$black};
  --background-50: #{get-color($black, 0.5)};
  --background-00: #{get-color($black, 0)};
  --foreground: #{$white};
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  * {
    font-family: degular-variable, sans-serif;
    text-align: center;
    font-feature-settings: 'onum';
    @include degular($wght: 400, $opsz: 16);
  }
}

html,
body {
  background-color: var(--background);
  color: var(--foreground);
  @include merge-transitions((background-color));
}

body {
  width: 100vw;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  &[data-theme='light'] {
    --background: #{$white};
    --background-50: #{get-color($white, 0.5)};
    --background-00: #{get-color($white, 0)};
    --foreground: #{$black};
  }
}

header {
  height: $header-height;
  width: 100vw;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-feature-settings: off;
  @include degular($wght: 700, $opsz: 36);
}

h1 {
  font-size: clamp(2.5rem, 5vw, 2.75rem);
  margin-bottom: clamp(0.75rem, 2.5vw, 1rem);
}

h2 {
  font-size: clamp(1.75rem, 4vw, 2rem);
  margin-bottom: clamp(0.5rem, 1.5vw, 0.75rem);
}

h3 {
  font-size: clamp(1.5rem, 3vw, 1.75rem);
  margin-bottom: clamp(0.25rem, 1.5vw, 0.5rem);
}

h4 {
  font-size: clamp(1.25rem, 2.5vw, 1.5rem);
  margin-bottom: 0.25rem;
}

h5 {
  font-size: clamp(1rem, 2vw, 1.25rem);
}

h6 {
  font-size: 1rem;
}

a {
  position: relative;
  color: currentColor;
  text-decoration: none;
  @include degular($wght: 400, $opsz: 16);

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 4px;
    background-color: $accent;
    bottom: -4px;
    left: 0;
    transform-origin: right;
    transform: scaleX(0);
    @include merge-transitions((transform));
  }

  &:hover,
  &:active,
  &.active {
    &::before {
      transform-origin: left;
      transform: scaleX(1);
    }
  }

  &.active {
    color: $accent;
  }

  &:focus-visible {
    @include accessibility-outline;
  }
}

button {
  &:focus-visible {
    @include accessibility-outline;
  }
}

::-webkit-scrollbar {
  width: 7px;

  &-track {
    background-color: transparent;
  }

  &-thumb {
    background-color: var(--foreground);
    background-clip: content-box;
    @include border($weight: 2px, $color: transparent);
    @include rounded-corners($radius: 10px);
    @include merge-transitions((background-color));

    &:hover {
      background-color: $accent;
    }
  }
}

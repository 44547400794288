@import '@/styles/abstracts/index';

.icon-button {
  @extend %icon-button;
  display: flex;
  align-items: center;
  justify-content: center;

  & .bulb {
    font-family: var(--eat-icons-vf);
    color: var(--foreground);
    font-size: 20px;
    text-align: center;
    width: 20px;
    height: 20px;
    color: var(--foreground);
    @include merge-transitions((color));
  }

  @media (hover: hover) {
    &:hover {
      border-color: $accent;
      & .bulb {
        color: $accent;
      }
    }
  }

  &.active {
    border-color: $accent;
    & .bulb {
      color: $accent;
    }
  }
}

.screen-reader-only {
  @extend %screen-reader-only;
}

$breakpoint-xs: 0px;
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;

:export {
  breakpointXs: $breakpoint-xs;
  breakpointSm: $breakpoint-sm;
  breakpointMd: $breakpoint-md;
  breakpointLg: $breakpoint-lg;
  breakpointXl: $breakpoint-xl;
}
